import styles from './Skeleton.module.scss'
import SkeletonElement from './SkeletonElement';
import Shimmer from './Shimmer'

export default function SkeletonCard() {
  return ( 
    <div className={styles.wrapper}>
      <div className={styles.daelCard}>
        <SkeletonElement type="thumbnail"/>
        <SkeletonElement type="title"/>
        <SkeletonElement type="text"/>
      </div>
      <Shimmer />
    </div>
   );
}
 