import styles from './Skeleton.module.scss'

export default function Shimmer(){
  return(
    <div className={styles.shimmer_wrapper}>
        <div className={styles.shimmer}>
          
        </div>
    </div>
  )
}