import { useState, useContext, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Configure,
  useRefinementList,
} from "react-instantsearch-hooks-web";

// Font Awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTurnDownRight,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBadgePercent,
  faCalendar,
  faCalendarDays,
  faChevronRight,
  faCircleDollar,
  faDollarCircle,
  faDollarSign,
  faHandHoldingDollar,
  faLayerGroup,
  faTag,
} from "@fortawesome/pro-solid-svg-icons";

import CardsIcon from "../../public/icons/cards.svg";
import Deals from '../../public/icons/deals.svg';
import Sale from '../../public/icons/sale.svg';

// Swiper-related imports
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import { Navigation, FreeMode, Scrollbar } from "swiper";

// Custom components
import StoreContext from "../../context/store-context";
import Button from "../../components/Button/button";
import styles from "./Specials.module.scss";
import SkeletonElement from "../../components/skeletons/SkeletonElement";
import SkeletonDealCard from "../../components/skeletons/SkeletonDealCard";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_KEY
);
const indexName = "menu-products-by-category-and-name-asc-production";

// render product and bundle specials, display as grid or swiper (default swiper)
export default function JaneSpecials({ type, productSpecials, bundleSpecials, noIcon }) {
  const { store } = useContext(StoreContext);
  const [array, setArray] = useState([]);

  const normalizedArray = array.map((item) => ({
    id: item.value,
    title: item.label,
    photo: item.photo,
    description: item.description,
    product: true,
  }));

  const normalizedBundleSpecials =
    bundleSpecials?.map((item) => ({
      id: item.id,
      title: item.title,
      description: item.description,
      photo: item.photo,
      bundle: true,
    })) || [];

  const combinedArray = [...normalizedArray, ...normalizedBundleSpecials];

  return (
    <>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <Configure filters={`store_id:${store}`} />
        <CustomRefinementList
          attribute={"applicable_special_ids"}
          limit={100}
          productSpecials={productSpecials}
          array={array}
          setArray={setArray}
        />
      </InstantSearch>

      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <Configure filters={`store_id:${store}`} />
        {type === 'grid' ?
          <SpecialsGrid combinedArray={combinedArray} array={array} />
          :
          <SpecialsSwiper combinedArray={combinedArray} noIcon={noIcon} />
        }
      </InstantSearch>
    </>
  );
}

function SpecialsSwiper({ combinedArray, noIcon }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (combinedArray.length > 0) {
      setLoading(false);
    }
  }, [combinedArray]);

  const swiperRef = useRef(null);

  const limitedArray = combinedArray.slice(0, 8);

  return (
    <>
      <div className={styles.swiperWrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            {!noIcon && <div className={styles.icon}>
              <Deals />
            </div>}
            <h2>Deals</h2>
          </div>
          <Button type="link" href='/deals' className="sliderLink" prefetch={false} ariaLabel="View All Deals">
            <div>view all</div>
          </Button>
        </div>

        <Swiper
          modules={[Navigation, FreeMode, Scrollbar]}
          className={styles.specialsSwiper}
          breakpoints={{
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 14,
            },
            460: {
              slidesPerView: 1.5,
              slidesPerGroup: 1,
              spaceBetween: 10,
            },
            0: {
              slidesPerView: 1.2,
              slidesPerGroup: 1,
              spaceBetween: 10,
            }
          }}
          touchEventsTarget="container"
          navigation={{
            prevEl: `#specials-prev`,
            nextEl: `#specials-next`,
            clickable: true,
          }}
          scrollbar={{
            hide: true,
          }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
        >
          <div className={styles.prev} id="specials-prev">
            <Button type="button" className="slider-arrow" ariaLabel="Previous Slide">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </div>
          <div className={styles.next} id="specials-next">
            <Button type="button" className="slider-arrow" ariaLabel="Next Slide">
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
          {loading ? (
            [...Array(3)].map((_, index) => (
              <SwiperSlide key={index}>
                <SkeletonDealCard type="deal" />
              </SwiperSlide>
            ))
          ) : (
            <>
              {limitedArray.map((item) => (
                <SwiperSlide key={item.id} className={`${styles.slide} bundle-card`}>
                  <SpecialItem item={item} />
                </SwiperSlide>
              ))}
              <SwiperSlide className={`${styles.lastSlide}`}>
                <Link href="/deals">View All</Link>
              </SwiperSlide>
            </>
          )}
        </Swiper>
      </div>
    </>
  );
}


function SpecialsGrid({ combinedArray, array }) {

  return (
    <div>
      {!array.length > 0 ? (
        <div className={styles.grid}>
          {[...Array(12)].map((_, index) => (
            <div key={index}>
              <SkeletonElement type="special" key="index" />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.grid}>
          {combinedArray.map(item => (
            <SpecialItem key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  );
}

function SpecialItem({ item }) {
  const href = item.bundle ? `/deals/bundles/${item.id}` : `/shop?special=${item.id}`;
  const tagText = item.bundle ? "Bundle" : "Sale";
  const imageUrl = item.photo?.urls?.original;

  // Regular expression to match 'tax included' in any case
  const taxIncludedRegex = /tax included/i;
  const hasTaxIncluded = taxIncludedRegex.test(item.description);

  return (
    <Link href={href} className="bundle-card">
      <div className={styles.imageContainer}>
        {imageUrl ?
          <Image
            src={imageUrl}
            fill
            objectFit="cover"
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            alt={item.title}
          />
          :
          <div className={styles.fallbackTag}>
            {item.bundle ? <Deals /> : <Sale />}
            <span>{tagText}</span>
          </div>
        }
      </div>
      <div className={styles.content}>
        <div className="text-ellipsis fw-600 pb-1">
          {item.title}
        </div>
        <div className={styles.tags}>

          <span className={styles.tag}>
            <FontAwesomeIcon icon={item.bundle ? faLayerGroup : faTag} /> {tagText}
          </span>

          {hasTaxIncluded && (
            <span className={styles.taxIncluded}>
              <FontAwesomeIcon icon={faDollarCircle} />
              Tax Included
            </span>
          )}
        </div>
      </div>
    </Link>
  );
}

// use this to get all categories and sort/filter them, if type = category or sale
function CustomRefinementList(props) {
  const { items } = useRefinementList(props);
  const { productSpecials, setArray } = props;

  useEffect(() => {
    if (items) {
      const sharedItems = items
        ?.filter((item) =>
          productSpecials?.some((special) => special.id.toString() === item.label)
        )
        .map((sharedItem) => {
          // Find the matching productSpecial for the current sharedItem
          const matchingSpecial = productSpecials.find(
            (special) => special.id.toString() === sharedItem.label
          );
          return {
            ...sharedItem,
            label: matchingSpecial.title,
            highlighted: matchingSpecial.title,
            photo: matchingSpecial.photo,
            description: matchingSpecial.description,
          };
        });
      if (sharedItems) {
        setArray(sharedItems);
      }
    }
  }, [items, productSpecials, setArray]);
}
